<template>
<v-container>
      <v-layout row wrap class="mt-5 mb-5">
        <v-flex xs12>
            <v-toolbar flat color="white">
                <v-toolbar-title>Fill in intake form</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
        </v-flex>
        <v-flex xs8>
            <v-card flat>
                <v-card-text>
                  Congratulations! You went for a full electric or hybrid car at LeasePlan – excellent choice. Together with Blue Corner, we provide you with a charge point for your vehicle at home. <br>
                  The LeasePlan Home Pro package already includes a standard installation. <br>
                  This survey will give us more insight in the installation of the charge point and all the extra works that come with.<br>
                  Ideally, the charge point should be located as close as possible to your power box.
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs4>
          <v-img
            class="mt-4"
            src="/survey.png"
          ></v-img>
        </v-flex>
        <v-flex xs12>
            <v-card flat>
                <v-card-text>
                    <v-row>
                        <v-col  cols="12"  md="8">
                            <span class="title">Car</span> <br>
                            <span class="subtitle-1">Which type of car do you have/will soon be yours ?</span>
                            <v-text-field
                                v-model="payload.car"
                                required
                                autocomplete="off"
                                placeholder=" "
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  cols="12"  md="8">
                            <span class="title">House</span> <br>
                            <span class="subtitle-1">What type of electricity connection do you have at home ?</span>
                            <v-checkbox v-model="payload.powerConnection" value="13854" hide-details label="1 x 230V"></v-checkbox>
                            <v-checkbox v-model="payload.powerConnection" value="13855"  hide-details label="3 x 230V"></v-checkbox>
                            <v-checkbox v-model="payload.powerConnection" value="13856"  hide-details label="3 x 400 + N"></v-checkbox>
                            <br>
                            <span class="subtitle-1">Do you have high consumption devices :</span>
                            <v-checkbox v-model="payload.highConsDevice" value="13860" hide-details label="Heat pump"></v-checkbox>
                            <v-checkbox v-model="payload.highConsDevice" value="13859" hide-details label="Solar panels"></v-checkbox>
                            <v-checkbox v-model="payload.highConsDevice" value="13861" hide-details label="Air conditioning"></v-checkbox>
                            <v-checkbox v-model="payload.highConsDevice" value="13862" hide-details label="Induction cooking plate"></v-checkbox>
                            <v-checkbox v-model="payload.highConsDevice" value="13863" hide-details label="… (other)"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  cols="12"  md="8">
                            <span class="title">LOCATION CHARGER</span> <br>
                            <span class="subtitle-1">Will your charge point be installed indoor or outdoor?</span>
                <v-radio-group
                        v-model="payload.installation"
                        row
                    >
                        <v-radio
                            label="Indoor"
                            value="13825"
                        ></v-radio>
                        <v-radio
                            label="Outdoor"
                            value="13826"
                        ></v-radio>
                    </v-radio-group>
                <span class="subtitle-1">Will your charge point be located in the same room as your fuse box?</span>
                <v-radio-group
                        v-model="payload.location"
                        row
                    >
                        <v-radio
                            label="Yes"
                            value="13833"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="13834"
                        ></v-radio>
                    </v-radio-group>
                    <span class="subtitle-1" v-if="payload.location === 'yes'">How many walls are in between?</span>
                <v-text-field
                    v-if="payload.location === 'yes'"
                    v-model="payload.walls"
                    required
                    autocomplete="off"
                ></v-text-field>
                <span class="subtitle-1">Will they be located on the same floor?</span>
                <v-radio-group
                        v-model="payload.floor"
                        row
                    >
                        <v-radio
                            label="Yes"
                            value="13827"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="13828"
                        ></v-radio>
                    </v-radio-group>
                    <span class="subtitle-1">How many meters will there be between the fuse box and the location of your charge point? <br> (please calculate the distance by following the entire trajectory along the wall)</span>
                <v-text-field
                    v-model="payload.meter"
                    autocomplete="off"
                    placeholder=" "
                    required
                ></v-text-field>
                <span class="subtitle-1">What is the distance between your internet modem and the charge point? <br> (please calculate the distance by following the entire trajectory along the wall)</span>
                <v-text-field
                    v-model="payload.distanceWall"
                    autocomplete="off"
                    hint="meter"
                    required
                    class="mb-4"
                ></v-text-field>
                <span class="subtitle-1">Will there be any digging works needed?</span>
                    <v-radio-group
                        v-model="payload.digging"
                        row
                    >
                        <v-radio
                            label="Yes"
                            value="13831"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="13832"
                        ></v-radio>
                    </v-radio-group>
                        </v-col>
                    </v-row>
                <v-row>
                        <v-col  cols="12"  md="8">
                            <span class="title">Photos</span> <br>
                            <span class="subtitle-1">May we ask you to take photos of the following :</span>
                            <v-file-input
                                :rules="rules"
                                accept="image/png, image/jpeg, image/bmp"
                                v-model="payload.picJuncBox"
                                prepend-icon="mdi-camera"
                                label="Junction box (please make sure the fuse is visible) "
                            ></v-file-input>
                            <v-file-input
                                :rules="rules"
                                accept="image/png, image/jpeg, image/bmp"
                                v-model="payload.picFuseBox"
                                prepend-icon="mdi-camera"
                                label="Fuse box – from a distance, to give us a clear view of the area around the fuse box "
                            ></v-file-input>
                            <v-file-input
                                :rules="rules"
                                accept="image/png, image/jpeg, image/bmp"
                                v-model="payload.picJuncBoxOpenDoor"
                                prepend-icon="mdi-camera"
                                label="Junction box with open door"
                            ></v-file-input>
                            <v-file-input
                                :rules="rules"
                                accept="image/png, image/jpeg, image/bmp"
                                v-model="payload.picExactLocation"
                                prepend-icon="mdi-camera"
                                label="Exact location of the charge point "
                            ></v-file-input>
                            <v-file-input
                                :rules="rules"
                                accept="image/png, image/jpeg, image/bmp"
                                v-model="payload.picBackInternet"
                                prepend-icon="mdi-camera"
                                label="Back of the internet modem (where the cables are located) "
                            ></v-file-input>
                        </v-col>
                </v-row>
                <v-row>
                        <v-col  cols="12"  md="8">
                <v-textarea
                    v-model="payload.comments"
                    name="input-7-1"
                    label="Other comments you would like to mention to Blue corner?"
                    autocomplete="off"
                    >
                </v-textarea>
                 <v-btn
                    class="mr-4"
                    @click="send()"
                    color="primary"
                    :loading="isSaving"
                    >
                     Create
                    </v-btn>

                </v-col>
            </v-row>
                </v-card-text>
            </v-card>
        </v-flex>
      </v-layout>
</v-container>
</template>
<script>
import moment from 'moment'
import RequestApi from '../../services/RequestApi'
import RelationContactApi from '@/services/RelationContactApi'

export default {
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Pictures size should be less than 2 MB!'
    ],
    payload: {
      highConsDevice: [],
      powerConnection: '',
      airConditioning: null,
      car: '',
      comments: '',
      digging: '',
      distanceWall: '',
      floor: '',
      heatPump: null,
      houseOne: null,
      houseThree: null,
      houseTwo: null,
      inductionCookingPlate: null,
      installation: '',
      location: '',
      meter: '',
      other: null,
      solarPanels: null,
      walls: '',
      picJuncBox: null,
      picFuseBox: null,
      picJuncBoxOpenDoor: null,
      picExactLocation: null,
      picBackInternet: null
    },
    isSaving: false,
    type: 'month',
    currentDate: '',
    types: ['month', 'week', 'day', '4day'],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdays: [
      { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] }
    ],
    value: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    currentEventName: ''
  }),
  methods: {
    async send () {
      try {
        this.isSaving = true
        const dataProfile = await new RelationContactApi().userProfile()
        this.userData = dataProfile
        const emailAdr = this.userData.data.EmailAddress
        // const emailAdr = 'demo@demo.com'
        const payLoadData = {
          evtype: this.payload.car,
          highConsDevice: this.payload.highConsDevice,
          powerConnection: this.payload.powerConnection,
          chargerLocation: this.payload.installation,
          sameRoom: this.payload.location,
          sameFloor: this.payload.floor,
          distToFuse: this.payload.meter,
          distToModem: this.payload.distanceWall,
          digging: this.payload.digging,
          comments: this.payload.comments
        }
        // attach :
        /* const payloadTmp = {
          files: this.payload.picJuncBox
        } */
        let formData = new FormData()
        let imagefile = this.payload.picJuncBox
        if (this.payload.picJuncBox !== '' && this.payload.picJuncBox !== null) {
          formData.append('files', imagefile)
          console.log('execute call')
          try {
            const attach = await new RequestApi().attach(this.id, formData, emailAdr)
          } catch (error) {
            console.log(error)
          }
        }
        // picFuseBox
        if (this.payload.picFuseBox !== '' && this.payload.picFuseBox !== null) {
          formData = new FormData()
          imagefile = this.payload.picFuseBox
          formData.append('files', imagefile)
          console.log('execute call')
          try {
            const attach = await new RequestApi().attach(this.id, formData, emailAdr)
          } catch (error) {
            console.log(error)
          }
        }
        // picJuncBoxOpenDoor
        if (this.payload.picJuncBoxOpenDoor !== '' && this.payload.picJuncBoxOpenDoor !== null) {
          formData = new FormData()
          imagefile = this.payload.picJuncBoxOpenDoor
          formData.append('files', imagefile)
          console.log('execute call')
          try {
            const attach = await new RequestApi().attach(this.id, formData, emailAdr)
          } catch (error) {
            console.log(error)
          }
        }
        // picExactLocation
        if (this.payload.picExactLocation !== '' && this.payload.picExactLocation !== null) {
          formData = new FormData()
          imagefile = this.payload.picExactLocation
          formData.append('files', imagefile)
          console.log('execute call')
          try {
            const attach = await new RequestApi().attach(this.id, formData, emailAdr)
          } catch (error) {
            console.log(error)
          }
        }
        // picBackInternet
        if (this.payload.picBackInternet !== '' && this.payload.picBackInternet !== null) {
          formData = new FormData()
          imagefile = this.payload.picBackInternet
          formData.append('files', imagefile)
          console.log('execute call')
          try {
            const attach = await new RequestApi().attach(this.id, formData, emailAdr)
          } catch (error) {
            console.log(error)
          }
        }
        // var bodyFormData = new FormData()
        /* const payLoadNew = {
          evtype: this.payload.car,
          highConsDevice: this.payload.highConsDevice,
          powerConnection: this.payload.powerConnection,
          chargerLocation: this.payload.installation,
          sameRoom: this.payload.location,
          sameFloor: this.payload.floor,
          distToFuse: this.payload.meter,
          distToModem: this.payload.distanceWall,
          digging: this.payload.digging
        } */
        // console.log(payLoadData)
        const data = await new RequestApi().createSurvey(this.id, payLoadData, emailAdr)
        this.isSaving = false
        this.$router.push({ name: 'requestDetails', params: { id: this.id } })
        this.payload = {
          highConsDevice: [],
          powerConnection: '',
          airConditioning: null,
          car: '',
          comments: '',
          digging: '',
          distanceWall: '',
          floor: '',
          heatPump: null,
          houseOne: null,
          houseThree: null,
          houseTwo: null,
          inductionCookingPlate: null,
          installation: '',
          location: '',
          meter: '',
          other: null,
          solarPanels: null,
          walls: '',
          picJuncBox: null,
          picFuseBox: null,
          picJuncBoxOpenDoor: null,
          picExactLocation: null,
          picBackInternet: null
        }
      } catch (err) {
        console.log(err)
      }
    },
    getDate ($event) {
      console.log($event)
      this.currentDate = $event.event.start
      this.currentEventName = $event.event.name + ' - '
    },
    getEvents () {
      const events = []
      const min = moment(new Date('2020-11-04T08:00:00')).format('YYY-MM-DD hh:mm')
      const max = moment(new Date('2020-11-04T13:00:00')).format('YYY-MM-DD hh:mm')
      const minBis = moment(new Date('2020-11-04T14:00:00')).format('YYY-MM-DD hh:mm')
      const maxBis = moment(new Date('2020-11-04T19:00:00')).format('YYY-MM-DD hh:mm')
      const allDay = true
      /* events.push({
        name: 'MO',
        color: 'green',
        start: min,
        end: max,
        timed: true
      })
      events.push({
        name: 'AF',
        color: 'green',
        start: minBis,
        end: maxBis,
        timed: false
      }) */
      // let startDate = new Date()
      let start = moment().toDate()
      let end = moment().toDate()
      for (let index = 0; index < 69; index++) {
        const base = start
        start = moment(base).add(1, 'days').toDate()
        end = moment(base).add(1, 'days').toDate()
        const dayNumber = moment(start).weekday()
        if (dayNumber > 0 && dayNumber < 6) {
          let color = 'green'
          if (index % 3 === 0) {
            color = 'red'
          }
          events.push({
            name: 'MO',
            color: color,
            start: start,
            end: end,
            timed: false
          })
          start = moment(base).add(1, 'days').toDate()
          end = moment(base).add(1, 'days').toDate()
          if (index % 2 === 0) {
            color = 'red'
          }
          events.push({
            name: 'AF',
            color: color,
            start: start,
            end: end,
            timed: false
          })
        }
        // start.add
        // startDate = startDate.setDate(startDate.getDate() + 1)
      }
      // moment().add(1, 'days').calendar()
      // moment().format('LLL')
      this.events = events
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  },
  filters: {
    formatDate (val) {
      if (val !== '') {
        return moment(val).format('LL')
      } else {
        return 'No'
      }
    }
  },
  props: ['id']
}
</script>
